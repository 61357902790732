import React from 'react';
import { WORK_FLOW_TYPES, VSO_STATES, VSO_STATE_INFO, VSO_PARTNER_TESTING_STATES, VSO_PARTNER_TESTING_STATE_INFO, BUTTON_STATE } from '../../Constants/enums';
import Auth from '../../Auth/Auth';
import { FormFieldValidationResult } from '../../Components/FormFields/FormField';

export type AdoIdentity = {
    displayName: string
}

export type DisplayField<V> = {
    name: string;
    value: V;
    id: string;
    url?: string;
}

export class OfferField<V> {
    value: V;
    displayName: string;
    validate?: (<T extends MarketplaceItem>(offer?: T, value?: V, originalValue?: V, enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) => FormFieldValidationResult);
    serverExport: boolean;
    convertJson: boolean;
    visibleTo: string[] | null;

    constructor(value: V, displayName: string, serverExport: boolean, visibleTo: string[] | null = null, convertJson: boolean = false) {
        this.value = value;
        this.displayName = displayName;
        this.serverExport = serverExport;
        this.convertJson = convertJson;
        this.visibleTo = visibleTo;
    }

    convertToDisplayField(productDetails: DisplayField<V>[], url?: string) {

        if (this.value !== null) {
            let displayField: DisplayField<V> = {
                name: this.displayName,
                value: this.value,
                id: this.displayName.replace(/\s+/g, ''),
                url: url
            }

            productDetails.push(displayField);
        }
    }

    copyValue(offerField: OfferField<V>) {
        this.value = offerField.value;
    }
}

export const visibleToOfferAndMetaWorkFlow = [ WORK_FLOW_TYPES.METADATA_ONLY, WORK_FLOW_TYPES.OFFER];
export const visibleToMetaWorkFlow = [WORK_FLOW_TYPES.METADATA_ONLY];

export function shouldDisplayFormField<V>(offer: OfferField<V>, isNew: boolean, workflowType: string) {

    const shouldDisplay = isNew 
                       || (workflowType && offer.visibleTo != null && offer.visibleTo.includes(workflowType))
                       || (workflowType && offer.visibleTo === null)    // all workFlowTypes
                       ;

     if (!shouldDisplay) {
         console.log(`Field '${offer.displayName}' not shown for workflowType`, workflowType || "none");
     }
     return shouldDisplay;
}

interface Props {
    offerId: string
    cardId: string
    auth: Auth
    workflowType: string
}

export class MarketplaceItem {
    auth: Auth;
    workflowType = new OfferField<string | null>(null, "Workflow Type - not for display", false);

    constructor(data: Props) {
        this.auth = data.auth;
        this.workflowType.value = data.workflowType;
    }

    revertEditsToFieldsNotInSelectedWorkflow<T extends MarketplaceItem>(originalValues: T) {
        const workflowType: string = this.workflowType.value as string;
        for (var prop in this) {
            if (this.hasOwnProperty(prop)) {
                if (this[prop] && this[prop] instanceof OfferField) {
                    const offerField = this[prop] as OfferField<any>;

                    if (offerField.value !== null && offerField.serverExport) {
                        if (workflowType && offerField.visibleTo && offerField.visibleTo.includes(workflowType) === false) {
                            // field is only valid for certain workflows and this isn't one of them.  revert to the server value
                            offerField.value = (originalValues[prop as keyof T] as OfferField<any>).value;
                        }
                    }
                }
            }
        }
    }

    export() {
        var exportObject: any = {};
        for (var prop in this) {
            if (this.hasOwnProperty(prop)) {
                if (this[prop] && this[prop] instanceof OfferField) {
                    const offerField = this[prop] as OfferField<any>;

                    if (offerField.value !== null && offerField.serverExport) {
                        exportObject[prop] = offerField.value;
                    }
                }
            }
        }

        if (exportObject.auth) {
            delete exportObject.auth;
        }

        return exportObject;
    }

    validateOffer(enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, originalValues: any = null) : {passed: boolean, results: {prop: string, message: string | undefined}[]} {
        let errors: {prop: string, message: string | undefined}[] = [];
        let warnings: {prop: string, message: string | undefined}[] = [];
        console.log("--------BEGINNING VALIDATION--------");
        for (var prop in this) {
            if (this.hasOwnProperty(prop) && this[prop] instanceof OfferField) {
                const offerField = this[prop] as OfferField<any>;

                if (offerField.validate) {
                    var originalValue = null;

                    if (originalValues !== null && originalValues.hasOwnProperty(prop)) {
                        originalValue = originalValues[prop].value;
                    }

                    const {
                        result,
                        message,
                        blockSubmission,
                        requestConfirmation
                    } = offerField.validate(this, offerField.value, originalValue, enablePCAndXbox, enablePCOnly, enableXboxOnly);

                    if (blockSubmission || (result !== 'success' && result !== 'warning') ) {
                        console.log(`${prop} failed validation with error: ${message}`)
                        errors.push({prop: prop[0].toUpperCase() + prop.slice(1), message: message});
                        continue;
                    } else if (result === 'warning' && requestConfirmation) {
                        console.log(`${prop} passed validation with warning: ${message}`)
                        warnings.push({prop: prop[0].toUpperCase() + prop.slice(1), message: message});
                    }
                }
            }
        }

        if (errors.length === 0 ) {
            console.log("------PASSED VALIDATION--------");

            return {
                passed: true,
                results: warnings
            }
        }
        else{
            console.log("--------FAILED VALIDATION--------");

            return {
                passed: false,
                results: errors
            }
        }
    }

    getTitle() {
        throw new Error(`getTitle not defined in ${typeof this}`);
    }

    
    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        if (this.hasOwnProperty(propertyName)) {
            if (propertyValue !== undefined) {
                (this[propertyName] as OfferField<any>).value = propertyValue;
            }

            return this;
        } else {
            console.log(`Can't find prop "${propertyName}" in ${typeof this}!`, this);
            throw new Error(`Can't find prop "${propertyName}" in ${typeof this}!`);
        }
    }

    getSubmissionFormFields(formInfoCallback: <T extends MarketplaceItem, V>(offer: T | undefined, propertyName: keyof T, value?: V) => void, isNew: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {
        throw new Error(`getSubmissionFormFields not defined in ${typeof this}`);
    }

    exportForSubmission(updateSubmissionDate: boolean = true, exportComments: boolean = true): any {
        throw new Error(`exportForSubmission not defined in ${typeof this}`);
    }

    getShipDateOverride() {
        throw new Error(`getShipDateOverride not defined in ${typeof this}`);
    }

    getApprovalNotice() {
        throw new Error(`getApprovalNotice not defined in ${typeof this}`);
    }

    getComments() {
        throw new Error(`getComments not defined in ${typeof this}`);
    }

    isInApproval() {
        throw new Error(`isInApproval not defined in ${typeof this}`);
    }

    getVisibleProductDetails() {
        throw new Error(`getVisibleProductDetails not defined in ${typeof this}`);
    }

    getSubmissionStatusDetails() {
        throw new Error(`getSubmissionStatusDetails not defined in ${typeof this}`);
    }

    getSubmissionPartnerTestingStatusDetails(isFirstParty: boolean) {
        throw new Error(`getSubmissionStatusDetails not defined in ${typeof this}`);
    }

    getTestReports() {
        throw new Error(`getTestReports not defined in ${typeof this}`);
    }

    getActionButton(isFirstParty: boolean, onPartnerRequestResubmissionCallback: React.MouseEventHandler<HTMLElement>) {
        throw new Error(`getActionButton not defined in ${typeof this}`);
    }

    /* offers with their own Purchasable property will override this property */
    getPurchasableStatus() {
        return true;
    }

    getTakedownButton(parentCallback: () => void) {
        throw new Error(`getTakedownButton not defined in ${typeof this}`);
    }

    getIngestButton(parentCallback: () => void) {
        throw new Error(`getIngestButton not defined in ${typeof this}`);
    }

    getPartnerTestingButtons(isFirstParty: boolean, onPartnerTestingCallback: (state?: string) => void) {
        throw new Error(`getPartnerTestingButtons not defined in ${typeof this}`);
    }

    getReadyToIngestButton(isFirstParty: boolean, onReadyToIngestCallback: React.MouseEventHandler<HTMLElement>) {
        throw new Error(`getReadyToIngestButton not defined in ${typeof this}`);
    }

    convertToDisplayField<V>(productDetails: DisplayField<V>[], offerField: OfferField<V>, url?: string) {
        offerField.convertToDisplayField(productDetails, url)
    }

    static get VSO_STATES() {
        return VSO_STATES;
    }

    static get VSO_STATE_INFO() {
        return VSO_STATE_INFO;
    };

    static get VSO_PARTNER_TESTING_STATES() {
        return VSO_PARTNER_TESTING_STATES;
    }

    static get VSO_PARTNER_TESTING_STATE_INFO() {
        return VSO_PARTNER_TESTING_STATE_INFO;
    };
}