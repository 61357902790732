import React, { useEffect, useState } from 'react';
import { SlingshotRole, User } from "../../Models/User";
import { Alert, Button, Card, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { formStyle } from '../../Constants/styles';
import { createUserV2, updateUserV2 } from '../../Api/WebApi';
import Auth from '../../Auth/Auth';
import { DropDownFormField, GenericCheckboxForm, TagFormField, TextFormField } from '../FormFields/FormField';
import { Tag } from '../FormFields/TagInput';

interface Props {
    auth: Auth;
    user: User;
    partners: Tag[]
    isNew: boolean;
    updateUserCallback: any;
}

function UserModal(props: Props) {
    const [user, setUser] = useState<User>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>();
    
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
      
    useEffect(() => {
        if (props.user === null || props.user === undefined) {
            setUser(new User());
        } else {
            setUser(props.user);
        }
    }, [props.user]);

    const saveChanges = () => {
        const createNewUser = async () => {
            const result: User = await createUserV2(props.auth, user)
                .catch((e) => {
                    console.log("Error creating user: ", e);
                    setError(e.response.data)
                });

            if (result !== null && result !== undefined) {
                setUser(new User());
                setError(null);
                props.updateUserCallback(result);
                closeModal();
            }
        }

        const updateExistingUser = async () => {
            const result: User = await updateUserV2(props.auth, user)
                .catch((e) => {
                    console.log("Error updating user: ", e);
                    setError(e.response.data)
                });

            if (result !== null && result !== undefined) {
                setUser(result);
                setError(null);
                props.updateUserCallback(result);
                closeModal();
            }
        }

        if (props.isNew) {
            createNewUser()
        } else {
            updateExistingUser()
        }
    };

    const updateUserProperty = function <V>(offer: User | undefined, propertyName: keyof User, value: V) {
        setUser((prevUser: any) => ({
            ...prevUser,
            [propertyName]: value,
        }));
    };

    const getUserFormFields = (): JSX.Element[] => {
        let formFields: JSX.Element[] = [];
      
        if (user === null || user === undefined) {
          return formFields;
        }
    
        formFields.push(<TextFormField<User>
            key={"email"}
            value={(user?.email) ? user.email : ''}
            title={"Email"}
            placeholder={"Email"}
            helpContent={"User Email"}
            propertyName={"email"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<User>
            key={"name"}
            value={(user?.name) ? user.name : ''}
            title={"Name"}
            placeholder={"Name"}
            helpContent={"User Name"}
            propertyName={"name"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<User>
            key={"gamerTag"}
            value={(user?.gamerTag) ? user.gamerTag : ''}
            title={"GamerTag"}
            placeholder={"GamerTag"}
            helpContent={"User GamerTag"}
            propertyName={"gamerTag"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
        
        formFields.push(<TagFormField<User>
            key={"partners"}
            value={(user?.partners) ? user.partners : []}
            title={"Partners"}
            placeholder={"Partner Short Name"}
            helpContent={"Type in the short name of a partner, and hit enter."}
            propertyName={"partners"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            disabled={false}
            optionsList={props.partners}
            offerUndo={false} />);

        const roleTypeOptions = Object.values(SlingshotRole).map(key => {
            return {
                option: SlingshotRole[key as keyof typeof SlingshotRole],
                disabled: false
            };
        });
        formFields.push(<DropDownFormField<User>
            key={"role"}
            value={(user?.role) ? user.role : SlingshotRole.External}
            title={"Role"}
            isNew={props.isNew}
            helpContent={"User Role"}
            propertyName={"role"}
            returnCallback={updateUserProperty}
            disabled={false}
            offerUndo={false}
            optionsList={roleTypeOptions} />);
    
        formFields.push(<GenericCheckboxForm<User>
            key={"approved"}
            title={"Approved"}
            value={(typeof user.approved === 'boolean') ? user.approved : user.approved === 'true'}
            helpContent={"Approved"}
            propertyName={"approved"}
            returnCallback={updateUserProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Approved'}]} 
            offerUndo={false} />);
    
        return formFields;
    };

    return (
        <div className='modal-container'>
            <div className='modal-button'>
                {!props.isNew && <FontAwesomeIcon icon={faPenToSquare} className="icon" onClick={openModal}/>}
                {props.isNew && <Button variant="success" size="sm" onClick={openModal}>CREATE NEW USER</Button>}
            </div>
            <div className="userModal">
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{(props.isNew) ? 'Create New User' : props.user?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={formStyle} >
                            {getUserFormFields()}
                        </Card>
                        <div>
                            {error && (
                                <Alert variant="danger" onAbort={() => {setError(null)}}>
                                    <h4>Error:</h4>
                                    <p>{error}</p>
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={saveChanges}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
      );
}

export default UserModal;