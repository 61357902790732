import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Home from './Home/Home';
import ContentBrowser from './Components/ContentBrowser';
import MISContentBrowser from './Components/MISContentBrowser';
import Documentation from './Documentation/Documentation';
import Validator from './Validator/Validator';
import CreateUpdate from './Components/CreateUpdate';
import ViewOffer from './Components/ViewOffer';
import ViewReport from './Components/ViewReport';
import ViewValidationReport from './Components/ViewValidationReport';
import CreateNewOffer from './Components/CreateNewOffer';
import Auth from './Auth/Auth';
import logoImage from './Images/microsoft_studios.png';
import { Image, Card } from 'react-bootstrap';
import { center, formStyle, msStudiosLogoFooter } from './Constants/styles';
import version from './version.json';
import UserBrowser from './Components/User/UserBrowser';
import PartnerBrowser from './Components/Partner/PartnerBrowser';
import CIDSContentBrowser from './Components/CIDSContentBrowser';

const auth = new Auth();

export const makeMainRoutes = () => {
  if (false || !!document.documentMode) {
    // you are using IE
    return (
      <div>
        <h1 style={{ color: "red"}}>!!!!!</h1>
      </div>
    )
  }

  let creatorRole = auth.getCreatorRole();
  let creatorLoggedInVersion = auth.getCreatorLoggedInVersion();

  if (creatorLoggedInVersion && creatorLoggedInVersion !== version.version) {
    creatorRole = 'External';

    auth.login();
  }

  if (process.env.REACT_APP_OFFLINE_FOR_MAINTENANCE === 'true' && creatorRole === 'External') {
    const message = process.env.REACT_APP_OFFLINE_MESSAGE;

    return (
      <main className="container">
        <div className="row">
          <Card style={formStyle} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div style={center}>
              <h4>{message}</h4>
            </div>
          </Card>
        </div>
      </main>
    );
  } else {
    return (
      <div>
        <BrowserRouter>
        <App auth={auth} />
          <Routes>
            <Route path="/" element={<Home auth={auth} />} />
            <Route path="home" element={<Home auth={auth} />} />
            <Route path="viewOffer/:offerId" element={<ViewOffer auth={auth} />} />
            <Route path="viewReport/:reportId" element={<ViewReport auth={auth} />} />
            <Route path="viewValidationReport/:reportId" element={<ViewValidationReport auth={auth} game={2020} />} />
            <Route path="createupdate/:offerId" element={<CreateUpdate auth={auth} />} />
            <Route path="createNewOffer/:offerType/:offerId?" element={<CreateNewOffer auth={auth} />} />
            <Route path="contentBrowser/:offerType?" element={<ContentBrowser auth={auth} />} />
            <Route path="misContentBrowser/:offerType?" element={<MISContentBrowser auth={auth} />} />
            <Route path="cidsContentBrowser/:offerType?" element={<CIDSContentBrowser auth={auth} />} />
            <Route path="partnerBrowser" element={<PartnerBrowser auth={auth} />} />
            <Route path="userBrowser" element={<UserBrowser auth={auth} />} />
            <Route path="validator" element={<Validator auth={auth} />} />
            <Route path="documentation/:page" element={<Documentation auth={auth} prefix={"documentation"}/>} />
            <Route path="comingsoon/:page" element={<Documentation auth={auth} prefix={"comingsoon"}/>} />
          </Routes>
        </BrowserRouter>
        <Image src={logoImage} style={msStudiosLogoFooter} thumbnail />
        {<footer style={{textAlign: "center", color:"white"}}>

        Microsoft &copy; 2018 - 2022 | Xbox Studios | Internal and Partner Use Only | {version.version} <br/> <a style={{color:"white"}} href="attributions.txt" download>Attributions</a></footer>}
      </div>
    );
  }
};
