export enum SlingshotRole {
    Admin = 'Admin',
    Internal = 'Internal',
    External = 'External'
}

export class User {
    id?: string;
    _etag?: string;
    pk?: string;
    created?: string;
    modified?: string;
    email: string = '';
    name: string = '';
    gamerTag: string = '';
    role: SlingshotRole | string = SlingshotRole.External;
    partners: string[] = [];
    approved: boolean | string = false;
}