import React, { useEffect, useState } from 'react';
import { Button, Card, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { center } from '../Constants/styles'
import { AUGER_OFFER_TYPES, VALIDATION_DECISIONS } from '../Constants/enums'
import { useNavigate } from 'react-router-dom';
import Auth from '../Auth/Auth';
import { CIDSContentList } from './CIDSContentList';
import { GetContentPackages } from '../Api/RomaWebApi';
import { FileFormField, FormFieldValidationResult, ValidationFormField, ValidationReport } from './FormFields/FormField';
import { DurableOfferModel } from '../Models/AugerOffers/DurableOfferModel';

interface Props {
    auth: Auth;
}
  
function CIDSContentBrowser(props: Props) {
    const [cidSubmissions, setCIDSubmissions] = useState<any | null>();
    const [ingestPackage, setIngestPackage] = useState(false);
    const [enableDismissButton, setEnableDismissButton] = useState(false);
    const [binaryFile, setBinaryFile] = useState<File | null>();
    const [error, setError] = useState<any>();

    useEffect(() => {
        GetContentPackages(props.auth).then((result: any) => {
            if (result.count === 0) {
                console.log("No Offers Found");
                setCIDSubmissions([]);
                setError("No offers found");
            } else {
                setCIDSubmissions(result.value);
            }
        }).catch((error) => {
            console.log("error", error);
            setCIDSubmissions(undefined);
            setError(error);
        });
    }, [props.auth]);

    const navigate = useNavigate();

    useEffect(() => {
        const lastBrowserPathName = "/cidsContentBrowser/" + AUGER_OFFER_TYPES.CIDS_OFFER;
        navigate(lastBrowserPathName);
    }, [navigate]);
    
    function chooseFile(files: FileList | null) {
        if (files && files.length === 1) {
            setBinaryFile(files[0]);
            setIngestPackage(true);
        }
    }

    function processFormInfo(offer?: any, propertyName?: keyof any, propertyValue?: ValidationReport) { 
        if (propertyName!= 'validationResult' || !propertyValue) {
            return;
        }

        if (propertyValue.decision !== "Pending") {
            setEnableDismissButton(true);
        }
    }

    function formValidate(offer: any, file?: File): FormFieldValidationResult {
        if (file) {
            if (file.size < (DurableOfferModel.MAX_FILE_SIZE)) {
                var extension = file.name.split('.').pop();
                if (extension === "zip") {
                    //console.log("success on BinaryValidation", file)
                    return {
                        result: "success"
                    };
                }
                else {
                    return {
                        result: "error"
                    };
                }
            }
        }
        //console.log("error on BinaryValidation")
        return {
            result: null
        }
    }

    function validateValidate(offer: any, value?: ValidationReport): FormFieldValidationResult {
        if (value) {
            //console.log("validatoinResults", results);
            if (value.decision === VALIDATION_DECISIONS.PASS || value.decision === VALIDATION_DECISIONS.PASS_WITH_WARNING) {
                return {
                    result: "success"
                };
            }
            else if (value.decision === VALIDATION_DECISIONS.PENDING) {
                return {
                    result: "warning",
                    blockSubmission: true
                }
            }
            else {
                return {
                    result: "error"
                };
            }
        } else {
            return {
                result: null
            }
        }
    }

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card className="text-center">
                        <div style={center} >
                            <InputGroup style={{justifyContent: "center", marginTop: "40px"}} className="custom-file-button">
                                <label className="input-group-text" style={{background: "linear-gradient(#89b659, #73a839 60%, #6c9e36)", borderTopRightRadius: "6px", borderBottomRightRadius: "6px", color: "white"}} htmlFor="inputGroupFile">INGEST PACKAGE</label>
                                <FormControl
                                    id="inputGroupFile"
                                    type="file"
                                    hidden={true}
                                    accept=".zip"
                                    onChange={(e) => chooseFile(((e.currentTarget as any) as HTMLInputElement)?.files)} />
                            </InputGroup>
                        </div>
                        {ingestPackage &&
                            <Modal show={true} onHide={() => setIngestPackage(false) } backdrop={"static"} keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title style={center}>Ingest Package</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={center} >
                                        <ValidationFormField
                                            key={"validationResult"}
                                            game={2024}
                                            title={"Validation Results"}
                                            placeholder={""}
                                            helpContent={`Your content must pass validation in order to submit.`}
                                            propertyName={"validationResult"}
                                            isNew={true}
                                            auth={props.auth}
                                            binaryFile={binaryFile as File}
                                            packType={"SCENERY"}
                                            returnCallback={processFormInfo}
                                            isAnUpdate={false}
                                            offerId={""}
                                            offerType={"CIDS_OFFER"}
                                            validateCallback={validateValidate} />
                                    </div>
                                    <div style={center}>
                                        <Button disabled={enableDismissButton !== true} onClick={() => setIngestPackage(false)}>Dismiss</Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        }
                        <CIDSContentList type={AUGER_OFFER_TYPES.CIDS_OFFER} submissions={cidSubmissions} auth={props.auth} />
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default CIDSContentBrowser;