import {
    msalApp,
    requiresInteraction
} from "./auth-utils";
// import history from '../history';
import { CheckUserAuth } from '../Api/WebApi';
import version from '../version.json';

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getCreatorUser = this.getCreatorUser.bind(this);
    this.getCreatorName = this.getCreatorName.bind(this);
    this.getCreatorId = this.getCreatorId.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.isApprovedCreator = this.isApprovedCreator.bind(this);
    this.isSignedInWithMSA = this.isSignedInWithMSA.bind(this);
  }

  async acquireToken(request) {
      return msalApp.acquireTokenSilent(request).catch(error => {
          // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
          // due to consent or interaction required ONLY
          if (requiresInteraction(error.errorCode)) {
              return msalApp.acquireTokenPopup(request);
          } else {
              console.error('Non-interactive error:', error)
          }
      });
  }

  async onSignIn() {
    await msalApp.loginPopup({
      scopes: [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ]
    }).catch(error => {
        this.error = error.message;
    });

    let token = await this.getAccessToken(false);
    let result = await CheckUserAuth(token);
    
    if (result) {
      localStorage.setItem('api_auth', true);
      localStorage.setItem('creator_user', result.creatorUser);
      localStorage.setItem('creator_name', result.creatorName);
      localStorage.setItem('creator_short_name', result.creatorShortName);
      localStorage.setItem('creator_id', result.creatorId);
      localStorage.setItem('creator_club', result.creatorClub ?? false)
      localStorage.setItem('creator_role', result.creatorRole);
      localStorage.setItem('creator_logged_in_version', version.version);
    }

    return token;
  }

  async login() {
    return await this.onSignIn();
  }

  isApprovedCreator() {
    return JSON.parse(localStorage.getItem('api_auth'));
  }

  async getAccessToken(signInOnError = true) {
    const myAccounts = msalApp.getAllAccounts();
    const tokenResponse = await this.acquireToken({
      account: myAccounts[0],
      scopes: [
        `api://${process.env.REACT_APP_AZURE_APPLICATION_ID}/Offers.View`
      ]
    }).catch(async () => {
      return null;
    });

    if (tokenResponse?.account) {
      msalApp.setActiveAccount(tokenResponse.account);

      return tokenResponse.accessToken;
    }
    else if (signInOnError) {
      return await this.onSignIn();
    }
    else {
      return null;
    }
  }

  getCreatorUser() {
    const creatorUser = localStorage.getItem('creator_user');
    if (!creatorUser) {
      return "";
    }
    return creatorUser;
  }

  getCreatorName() {
    return localStorage.getItem('creator_name');
  }

  getCreatorShortName() {
    return localStorage.getItem('creator_short_name');
  }

  getCreatorId() {
    return localStorage.getItem('creator_id');
  }

  getCreatorClub() {
    return JSON.parse(localStorage.getItem('creator_club'));
  }

  getCreatorRole() {
    return localStorage.getItem('creator_role');
  }

  getCreatorLoggedInVersion() {
    return localStorage.getItem('creator_logged_in_version');
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  logout() {
    msalApp.logoutRedirect();

    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('api_auth');
    localStorage.removeItem('creator_user');
    localStorage.removeItem('creator_name');
    localStorage.removeItem('creator_short_name');
    localStorage.removeItem('creator_club');
    localStorage.removeItem('creator_role');
    localStorage.removeItem('creator_logged_in_version');
    this.userProfile = null;
  }

  isSignedInWithMSA() {
    return msalApp.getActiveAccount() !== null;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    var isApprovedCreator = this.isApprovedCreator();
    var isCurrent = this.isSignedInWithMSA();
    if (isApprovedCreator && isCurrent) {
      return true;
    } else {
      return false;
    }
  }
}
