export class Partner {
    id?: string;
    _etag?: string;
    pk?: string;
    created?: string;
    modified?: string;
    sellerId: string = '';
    name: string = '';
    shortName: string = '';
    partnerPriority: string = '';
    releaseOwner: string = '';
    enableBetaFeatures: boolean | string = false;
    approved: boolean | string = false;
    onboarded: boolean | string = false;
}