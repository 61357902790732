import React, { useEffect } from 'react';
import Auth from './Auth/Auth';
import { Card, Image, Navbar, Nav, NavItem, NavDropdown, NavLink, Container } from 'react-bootstrap';
import { mpLogoStyle, navBottom } from './Constants/styles'
import mpLogo from './Images/msf-logo_b85d0d89.png';
import * as utils from './Constants/utils';

import { SPLASH_TEXT } from './Constants/splashtext';

import './App.css';
import { useMatch, useNavigate } from 'react-router-dom';

interface Props {
  auth: Auth;
  navigate: any
}

function App (props: Props) {
  const login = () => {
    props.auth.login();
  }

  const logout = () => {
    props.auth.logout();
  }

  const handleSelect = (eventKey: string | null) => {
    if (eventKey as any === "logout") {
      logout();
    }
    if (eventKey as any === "login") {
      login();
    }
  }

  const match = useMatch("/home");
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!match && !props.auth.isApprovedCreator()) {
      navigate('/home');
    }
  });
  
  const { isAuthenticated, isSignedInWithMSA, isApprovedCreator } = props.auth;

  var isSignedMSA = isSignedInWithMSA();
  var isApproved = isApprovedCreator();

  var creatorUser = props.auth.getCreatorUser();
  var creatorId = props.auth.getCreatorId();

  var randomSplash = Math.floor(Math.random() * SPLASH_TEXT.length);

  var splashText = SPLASH_TEXT[randomSplash];
  splashText = splashText.replace(/\s+/g, '\xa0\xa0\xa0');

  return (
    <div>
      <Navbar style={{ marginBottom: 0 }} className="topnavbar navbar-dark">
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className='md-auto' onSelect={handleSelect}>
              {isAuthenticated() && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/contentBrowser">
                      Content Browser
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && (utils.isFirstParty(creatorId)) && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/misContentBrowser">
                      MIS Browser
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && (utils.isFirstParty(creatorId)) && (
                <NavItem>
                    <NavLink eventKey={"none"} href="/cidsContentBrowser">
                      CIDS Browser
                    </NavLink> 
                </NavItem>

              )}

              {isAuthenticated() && utils.isFirstParty(creatorId) &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/validator">
                      Validator
                    </NavLink>
                </NavItem>
              }

              {isAuthenticated() && utils.isFirstParty(creatorId) &&
                <NavDropdown title="PartnerDb" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/partnerBrowser" style={{textAlign: "left"}}>Partners</NavDropdown.Item>
                  <NavDropdown.Item href="/userBrowser" style={{textAlign: "left"}}>Users</NavDropdown.Item>
                </NavDropdown>
              }

              {isAuthenticated() &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/documentation/topics" >
                      Documentation
                    </NavLink>
                </NavItem>
              }

              {isAuthenticated() && (utils.isFirstParty(creatorId) || props.auth.getCreatorClub()) &&
                <NavItem>
                    <NavLink eventKey={"none"} href="/comingsoon/xboxpackagepreview" >
                      Coming Soon!
                    </NavLink>
                </NavItem>
              }
            </Nav>


            <Nav className="ms-auto" onSelect={handleSelect}>
              {(isSignedMSA || isApproved) &&
                <NavItem>
                    <NavLink eventKey={"none"} disabled>
                      Hello {creatorUser}!
                    </NavLink>
                </NavItem>

              }
              {(isSignedMSA || isApproved) &&

                <NavItem>
                    <NavLink eventKey={"logout"}>
                      Log Out
                    </NavLink>
                </NavItem>

              }
              {
                (!isSignedMSA && !isApproved) &&
                <NavItem>
                  <NavLink eventKey={"login"}>
                    Log In
                  </NavLink>
                </NavItem>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Card style={navBottom} >
        {<Image src={mpLogo} style={mpLogoStyle} thumbnail />}
      </Card>
      {
        utils.isFirstParty(creatorId) && <div className="position">
          <div className="tilt splash">
            <div id="statussplash" className="pop">{splashText}</div>
          </div>
        </div>}
    </div >
  );
}

export default App;
