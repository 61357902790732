import React, { useEffect, useState } from 'react';
import { User } from '../../Models/User';
import Auth from '../../Auth/Auth';
import ReactTable from '../../ReactTable';
import UserModal from './UserModal'
import { Column, ColumnStyle } from '../../Models/Column';
import { Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Partner } from '../../Models/Partner';
import { Tag } from '../FormFields/TagInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface Props {
    users: User[];
    partners: Partner[];
    auth: Auth;
}
  
function UserList(props: Props) {
    const [users, setUsers] = useState<User[] | null>();
    const [loading, setLoading] = useState<boolean>(true);
    const [searchEnabled, setSearchEnabled] = useState<boolean>(false);

    const toggleSearch = () => setSearchEnabled(!searchEnabled);

    useEffect(() => {
        if (props.users === null || props.users === undefined) {
            setUsers([]);
            setLoading(true);
        } else {
            setUsers(props.users);
            setLoading(false);
        }
    }, [props.auth, props.users]);

    const updateUserList = (newUser: User) => {
        if (users === null || users === undefined) {
            return [];
        }

        const updateOrAddUser = (): User[] => {
            const updatedUsers = users.map(user =>
              user.id === newUser.id ? newUser : user
            );
            if (!users.some(user => user.id === newUser.id)) {
              updatedUsers.push(newUser);
            }
            return updatedUsers;
          };
        
        setUsers(updateOrAddUser());
      };

    const getUserColumnData = ((): User[] => {
        if (users === null || users === undefined) {
            return [];
        }
    
        const convertedUsers: User[] = users.map((user) => {
            return {
              ...user,
              approved: user.approved.toString(),
              role: user.role.toString(),
            };
          });
    
        return convertedUsers;
    });

    const partnerTagOptions = props.partners.map(p => ({
            key: p.id, 
            value: p.shortName
        } as Tag
    ));

    const getUserColumnHeaders = ((isNew: boolean) : Column[] => {
        const columnStyle : ColumnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        };
    
        const columns : Column[] = [
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'Email',
                accessor: 'email',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'GamerTag',
                accessor: 'gamerTag',
                maxWidth: 200,
                minWidth: 50,
                width: 50,
                style: columnStyle,
            },
            {
                Header: 'Role',
                accessor: 'role',
                maxWidth: 200,
                minWidth: 20,
                width: 20,
                style: columnStyle,
            },
            {
                Header: 'Partners',
                accessor: 'partners',
                maxWidth: 200,
                minWidth: 100,
                width: 100,
                style: columnStyle,
                Cell: (row: any) => (
                    <div className="tags-container">
                        {row.row.original.partners.map((partnerId: string, index: any) => {
                            const matchedOption = partnerTagOptions?.find(o => o.key === partnerId);
                            const displayValue = matchedOption ? matchedOption.value : null;
                            return (displayValue && 
                                <Badge key={index} className="tag-badge pill">
                                    {displayValue}
                                </Badge>
                            );
                        })}
                    </div>
                )
            },
            {
                Header: 'Approved',
                accessor: 'approved',
                maxWidth: 200,
                minWidth: 20,
                width: 20,
                style: columnStyle,
            },
            {
                Header: '',
                accessor: 'edit',
                maxWidth: 10,
                minWidth: 10,
                width: 10,
                style: columnStyle,
                Cell: (row: any) => (
                    <UserModal auth={props.auth} user={row.row.original as User} partners={partnerTagOptions} isNew={isNew} updateUserCallback={updateUserList}/>
                )
            }
        ];
        return columns;
    });

    const userFilter = (rows: any, globalFilterValue: any) => {
        const filterValues = globalFilterValue.split(',');
        const result = rows.filter((row: any) => {
            const match = filterValues.find((fv: string) => Object.values(row.values).find((value: any) => {
                if (value === null || value === undefined) {
                    return false;
                }
                else if (typeof value === "string") {
                    return value.toLowerCase().includes(fv.trim().toLowerCase())
                }
                else if (Array.isArray(value)) {
                    const partnerNames = value.find((partnerId: string) => {
                        const matchedOption = partnerTagOptions?.find(o => o.key === partnerId);
                        const displayValue = matchedOption ? matchedOption.value : null;
                        return displayValue?.toLowerCase().includes(fv.trim().toLowerCase());
                    });

                    return partnerNames !== undefined && partnerNames !== null;
                }
                else {
                    return false;
                }
            }));

            return match?.trim();
        });
        
        return result;
    };

    return (
        <div>
            <div className="list-buttons-container">
                <UserModal auth={props.auth} user={new User()} partners={partnerTagOptions} isNew={true} updateUserCallback={updateUserList}/>
            </div>
            <ReactTable 
                data={getUserColumnData()}
                columns={getUserColumnHeaders(false)}
                loading={loading}
                defaultSorted={
                    [
                        {
                            id: "name",
                            desc: false
                        }
                    ]
                }
                defaultPageSize={20}
                customFilter={userFilter}
                className="-striped -highlight table-container"
            />
        </div>
    );
}

export default UserList;