import React, { useEffect, useState } from 'react';
import { Partner } from "../../Models/Partner";
import { Button, Card, Modal, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { formStyle } from '../../Constants/styles';
import { createPartnerV2, updatePartnerV2 } from '../../Api/WebApi';
import Auth from '../../Auth/Auth';
import { GenericCheckboxForm, TextFormField } from '../FormFields/FormField';

interface Props {
    auth: Auth;
    partner: Partner;
    isNew: boolean;
    updatePartnerCallback: any
}

function PartnerModal(props: Props) {
    const [partner, setPartner] = useState<Partner>();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        if (props.partner === null || props.partner === undefined) {
            setPartner(new Partner());
        } else {
            setPartner(props.partner);
        }
    }, [props.partner]);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const saveChanges = () => {
        const createNewPartner = async () => {
            const result: Partner = await createPartnerV2(props.auth, partner)
                .catch((e) => {
                    console.log("Error creating partner: ", e);
                    setError(e.response.data)
                });

            if (result !== null && result !== undefined) {
                setPartner(new Partner());
                setError(null);
                props.updatePartnerCallback(result);
                closeModal();
            }
        }

        const updateExistingPartner = async () => {
            const result: Partner = await updatePartnerV2(props.auth, partner)
                .catch((e) => {
                    console.log("Error updating partner: ", e);
                    setError(e.response.data)
                });

            if (result !== null && result !== undefined) {
                setPartner(result);
                setError(null);
                props.updatePartnerCallback(result);
                closeModal();
            }
        }

        if (props.isNew) {
            createNewPartner()
        } else {
            updateExistingPartner()
        }
    };

    const updatePartnerProperty = function <T>(offer: Partner | undefined, propertyName: keyof Partner, value?: T) {
        setPartner((prevPartner: any) => ({
            ...prevPartner,
            [propertyName]: value,
        }));
    };

    const getPartnerFormFields = (): JSX.Element[] => {
        let formFields: JSX.Element[] = [];
      
        if (partner === null || partner === undefined) {
          return formFields;
        }
    
        formFields.push(<TextFormField<Partner>
            key={"sellerId"}
            value={(partner?.sellerId) ? partner.sellerId : ''}
            title={"Seller Id"}
            placeholder={"SellerId"}
            helpContent={"Partner Seller Id"}
            propertyName={"sellerId"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Partner>
            key={"shortName"}
            value={(partner?.shortName) ? partner.shortName : ''}
            title={"Short Name"}
            placeholder={"Short Name"}
            helpContent={"Partner Short Name"}
            propertyName={"shortName"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={!props.isNew} // Form Field enabled only when creating a new partner
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Partner>
            key={"name"}
            value={(partner?.name) ? partner.name : ''}
            title={"Name"}
            placeholder={"Name"}
            helpContent={"Partner Name"}
            propertyName={"name"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Partner>
            key={"partnerPriority"}
            value={partner?.partnerPriority}
            title={"Partner Priority"}
            placeholder={"Partner Priority"}
            helpContent={"Partner Priority"}
            propertyName={"partnerPriority"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<TextFormField<Partner>
            key={"releaseOwner"}
            value={partner?.releaseOwner}
            title={"Release Owner"}
            placeholder={"Release Owner"}
            helpContent={"Release Owner"}
            propertyName={"releaseOwner"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            disabled={false}
            offerUndo={false} />);
    
        formFields.push(<GenericCheckboxForm<Partner>
            key={"enableBetaFeatures"}
            value={(typeof partner.enableBetaFeatures === 'boolean') ? partner.enableBetaFeatures : partner.enableBetaFeatures === 'true'}
            title={"Enable Beta Features"}
            helpContent={"Enable Beta Features"}
            propertyName={"enableBetaFeatures"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Enable Beta Features'}]} 
            offerUndo={false} />);
    
        formFields.push(<GenericCheckboxForm<Partner>
            key={"approved"}
            value={(typeof partner.approved === 'boolean') ? partner.approved : partner.approved === 'true'}
            title={"Approved"}
            helpContent={"Approved"}
            propertyName={"approved"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Approved'}]} 
            offerUndo={false} />);
    
        formFields.push(<GenericCheckboxForm<Partner>
            key={"onboarded"}
            value={(typeof partner.onboarded === 'boolean') ? partner.onboarded : partner.onboarded === 'true'}
            title={"Onboarded"}
            helpContent={"Onboarded"}
            propertyName={"onboarded"}
            returnCallback={updatePartnerProperty}
            isNew={props.isNew}
            checkboxFields={[{value: true, name: 'Onboarded'}]} 
            offerUndo={false} />);
    
        return formFields;
    };

    return (
        <div className='modal-container'>
            <div className='modal-button'>
                {!props.isNew && <FontAwesomeIcon icon={faPenToSquare} className="icon" onClick={openModal}/>}
                {props.isNew && <Button variant="success" size="sm" onClick={openModal}>CREATE NEW PARTNER</Button>}
            </div>
            <div className="partnerModal">
                <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{(props.isNew) ? 'Create New Partner' : props.partner?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card style={formStyle}>
                            {getPartnerFormFields()}
                        </Card>
                        <div>
                            {error && (
                                <Alert variant="danger" onAbort={() => {setError(null)}}>
                                    <h4>Error:</h4>
                                    <p>{error}</p>
                                </Alert>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={saveChanges}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
      );
}

export default PartnerModal;