import React from 'react';
import { Card } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { StatusBar } from './StatusBar';
import { MISContentDetails } from './MISContentDetails';

interface Props {
    auth: Auth,
    isNew: boolean,
    marketplacePackage: MarketplacePackage,
    packageNames: string[]
}

export function MISContentBrowserDetails(props: Props) {
    return (
        <div className="container create-new">
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card style={{ height: "auto" }}>
                        <MISContentDetails marketplacePackage={props.marketplacePackage} />
                        <h4>Microsoft Status</h4>
                        <StatusBar cardState={"Content In Testing"} offerType={props.marketplacePackage.offerType.value} />
                    </Card>
                </main>
            </div>
        </div>
    );
}
