import React, { Component } from 'react';

import { UploadFile, SubmitMetaData, MoveFile, GetBlobSasUri } from '../Api/WebApi';

import { UPDATE_STATUS, VSO_STATES } from '../Constants/enums';

import { UploaderModal } from './UploaderModal';

class UpdateUploader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadProgress : {
                binaryProgress: 0,
                metaProgress: 0,
                overallProgress: 0,
            },
            show: true,
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleBinaryProgress = this.handleBinaryProgress.bind(this);
        this.handleMetaProgress = this.handleMetaProgress.bind(this);

        this.handleButton = this.handleButton.bind(this);
    }

    async componentDidMount() {
        console.log("updatingWithThisInfo", this.props.updateInfo);

        if (!this.props.updateInfo) {
            return;
        }

        let moveFileResult = await MoveFile(this.props.auth, this.props.updateInfo.submissionId, this.props.updateInfo.binaryName, this.props.updateInfo.offerType).catch((error) => {
            console.log("binaryUploadError", error);
            return null;
        });

        console.log("binaryUploadResult", moveFileResult);
        
        let blobUrl = await GetBlobSasUri(this.props.auth, this.props.updateInfo.binaryName, this.props.updateInfo.creatorId).catch(error => {
            console.log("Error getting blob SAS uri:", error);
            return null;
        })

        let blobHost = new URL(blobUrl).host;

        this.props.updateInfo.relations = this.props.updateInfo.relations.filter(r => r.rel !== "Hyperlink" || new URL(r.url).host !== blobHost);
        this.props.updateInfo.relations.push({ url: blobUrl, rel: "Hyperlink", attributes: { comment: this.props.updateInfo.fileName } });
        
        this.props.updateInfo.cardTitle = (this.props.updateInfo.workItemPlatform === "PC" ? "[PC] " : "[Xbox] ") + this.props.updateInfo.offerTitle + " - $" + this.props.updateInfo.offerPrice;

        if (this.props.updateInfo.ingestionPlatforms === 'PC & Xbox' && this.props.updateInfoLinked) {
            this.props.updateInfoLinked.cardTitle = (this.props.updateInfoLinked.workItemPlatform === "PC" ? "[PC] " : "[Xbox] ") + this.props.updateInfoLinked.offerTitle + " - $" + this.props.updateInfoLinked.offerPrice;
            this.props.updateInfoLinked.binaryLocation = this.props.updateInfo.binaryLocation;
            this.props.updateInfoLinked.binaryName = this.props.updateInfo.binaryName;
            this.props.updateInfoLinked.relations = this.props.updateInfo.relations;
        }

        if (this.props.updateInfo && !this.props.updateInfo.cardId) {
            let updateResult = await SubmitMetaData(this.props.auth, this.props.updateInfo).catch((error) => {
                console.log("vsoUploadError", error);
                return null;
            });

            this.props.updateInfo.pipelineRunId = updateResult.pipelineRunId;
            this.props.updateInfo.cardId = updateResult.cardId;
            this.props.updateInfo.logComment = null;
        }

        if (this.props.updateInfoLinked && !this.props.updateInfoLinked.cardId) {
            if (this.props.updateInfo.ingestionPlatforms === "PC & Xbox") {
                this.props.updateInfoLinked.pipelineRunId = this.props.updateInfo.pipelineRunId;
            }

            let updateResult = await SubmitMetaData(this.props.auth, this.props.updateInfoLinked).catch((error) => {
                console.log("vsoUploadError", error);
                return null;
            });

            this.props.updateInfoLinked.cardId = updateResult.cardId;
            this.props.updateInfoLinked.logComment = null;
        }

        if (this.props.updateInfo) {
            if (this.props.updateInfo.cardState === VSO_STATES.PLANNED) {
                this.props.updateInfo.cardState = VSO_STATES.SUBMITTED;
            }

            this.props.updateInfo.tempLinkedItem = this.props.updateInfoLinked?.cardId;

            let updateResult = await SubmitMetaData(this.props.auth, this.props.updateInfo).catch((error) => {
                console.log("vsoUploadError", error);
                return null;
            });

            this.props.updateInfo.pipelineRunId = updateResult.pipelineRunId;

            console.log("vsoUploadResult", updateResult);
        }

        if (this.props.updateInfoLinked) {
            if (this.props.updateInfo.ingestionPlatforms === "PC & Xbox") {
                this.props.updateInfoLinked.pipelineRunId = this.props.updateInfo.pipelineRunId;
            }

            if (this.props.updateInfoLinked.cardState === VSO_STATES.PLANNED) {
                this.props.updateInfoLinked.cardState = VSO_STATES.SUBMITTED;
            }
            
            this.props.updateInfoLinked.tempLinkedItem = this.props.updateInfo?.cardId;

            let updateResult = await SubmitMetaData(this.props.auth, this.props.updateInfoLinked).catch((error) => {
                console.log("vsoUploadError", error);
                return null;
            });

            console.log("vsoUploadResult", updateResult);
        }
        
        let blobFile = new Blob([JSON.stringify(this.props.updateInfo, null, "\t")]);

        await UploadFile(this.props.auth, blobFile, `${this.props.updateInfo.binaryLocation}contentInfo.json`, this.handleMetaProgress, "uploadUri", this.props.updateInfo.creatorId).catch((error) => {
            console.log("metaUploadError", error);
            return null;
        });

        this.setState(() => ({ overallStatus: UPDATE_STATUS.success }));
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            uploadProgress
        } = this.state;

        var newProgress = uploadProgress;

        if (this.props.updateInfo.binaryFile){
            newProgress.overallProgress = (newProgress.metaProgress * 0.1) + (newProgress.binaryProgress * 0.9);
        }
        else {
            newProgress.overallProgress = newProgress.metaProgress;
        }
    }

    handleChange(event) {
        var newValue = event.target.value;
        this.setState(() => ({ value: newValue }));
        this.props.callbackFromParent("OfferTitle", newValue, this.getValidationState(newValue));
    }

    resetToDefault() {
        this.setState(() => ({ value: this.state.default }));
    }

    handleButton(overallStatus) {
        console.log("handleButton", overallStatus)
        this.props.callbackFromParent(overallStatus)
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleBinaryProgress(percentage){
        var newProgress = this.state.uploadProgress;
        newProgress.binaryProgress = percentage;
        this.setState({ uploadProgress: newProgress });
    }

    handleMetaProgress(percentage){
        var newProgress = this.state.uploadProgress;
        newProgress.metaProgress = percentage;
        this.setState({ uploadProgress: newProgress });
    }

    render() {
        const {
            overallStatus,
            uploadProgress,
        } = this.state;

        return (
            <UploaderModal overallStatus={overallStatus} uploadProgress={uploadProgress.overallProgress} returnButtonText="Return to Content Browser" callbackFromParent={this.handleButton}/>
        )
    }
}

export {
    UpdateUploader,
} 