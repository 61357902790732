import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { center, formStyle } from '../../Constants/styles'
import { getPartnerV2, getUserV2 } from '../../Api/WebApi'
import Auth from '../../Auth/Auth';
import UserList from './UserList';
import { User } from '../../Models/User';
import { Partner } from '../../Models/Partner';

interface Props {
    auth: Auth;
}

function UserBrowser(props: Props) {
    const [users, setUsers] = useState<User[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);

    useEffect(() => {
        document.title = "User Browser | Marketplace Content Portal";

        const fetchUserData = async () => {
            const result: User[] = await getUserV2(props.auth)
                .catch((e) => {
                    console.log("Error retrieving users: ", e);
                });

            if (result.length === 0) {
                setUsers([]);
            } else {
                setUsers(result);
            }
        }

        const fetchPartnerData = async () => {
            const result: Partner[] = await getPartnerV2(props.auth)
                .catch((e) => {
                    console.log("Error retrieving partners: ", e);
                });

            if (result.length === 0) {
                setPartners([]);
            } else {
                setPartners(result);
            }
        }

        fetchUserData();
        fetchPartnerData();
    }, [props.auth]);

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card style={formStyle}>
                        <h1 style={center}>Users</h1>
                        <UserList users={users} partners={partners} auth={props.auth} />
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default UserBrowser;