import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { center } from '../Constants/styles'
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { GetDeveloperIds, GetPublisherIds } from '../Api/RomaWebApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { MISContentBrowserThumbnails } from './MISContentBrowserThumbnails';

interface Props {
    auth: Auth,
    isNew: boolean,
    onSave: (marketplacePackage: MarketplacePackage) => void,
    onCancel: (marketplacePackage: MarketplacePackage) => void,
    processFormInfo: (marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    packageNames: string[]
}

export function MISModalEdit(props: Props) {
    const [marketplacePackageFormFields, setMarketplacePackageFormFields] = useState<JSX.Element>();
    const [validOffer, setValidOffer] = useState(false);
    const [developerIds, setDeveloperIds] = useState([]);
    const [publisherIds, setPublisherIds] = useState([]);
    const [isModified, setIsModified] = useState(false);
    const [editMediaAssets, setEditMediaAssets] = useState(false);

    useEffect(() => {
        GetPublisherIds(props.auth).then((result: any) => {
            setPublisherIds(result);
        });

        GetDeveloperIds(props.auth, props.marketplacePackage.publisherId.value as string).then((result: any) => {
            setDeveloperIds(result);
        });

        let enablePC = true; // this.props.params.offerId ? false : true;
        setMarketplacePackageFormFields(props.marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, enablePC, enablePC, !enablePC, {
            packageNames: props.packageNames
        }));
        setValidOffer(props.marketplacePackage.validateOffer(enablePC, enablePC, !enablePC).passed);
    }, [props.auth]);

    useEffect(() => {
        let enablePC = true; // this.props.params.offerId ? false : true;
        setMarketplacePackageFormFields(props.marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, enablePC, enablePC, !enablePC, {
            packageNames: props.packageNames,
            developerIds: developerIds,
            publisherIds: publisherIds
        }));
    }, [developerIds, publisherIds]);

    function processFormInfo(marketplacePackage: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) { 
        props.processFormInfo(marketplacePackage, propertyName, propertyValue);

        if (marketplacePackage) {
            if (propertyName as string === 'publisherId' && (propertyValue as string)) {
                GetDeveloperIds(props.auth, propertyValue as string).then((result: any) => {
                    setDeveloperIds(result);
                });
            }

            setIsModified(true);
        
            let enablePC = true; // this.props.params.offerId ? false : true;
            setMarketplacePackageFormFields(marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, enablePC, enablePC, !enablePC, {
                packageNames: props.packageNames,
                developerIds: developerIds,
                publisherIds: publisherIds
            }));
            setValidOffer(marketplacePackage.validateOffer(enablePC, enablePC, !enablePC).passed);
        }
    }

    function onEditMediaAssets() {
        setEditMediaAssets(true);
    }

    function onSave(submission: MarketplacePackage) {
        setEditMediaAssets(false);
        setIsModified(true);
    }

    function onCancel() {
        setEditMediaAssets(false);
    }

    return (
        <div className="container create-new">
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {props.marketplacePackage && <MISContentBrowserThumbnails show={editMediaAssets} auth={props.auth} marketplacePackage={props.marketplacePackage} onSave={onSave} onCancel={onCancel} />}
                        <h1 style={center}>{props.isNew ? "New Marketplace Product" : "Edit Marketplace Product"}</h1>
                        <div style={center}><em>Note: This is a helpful note.</em></div>
                        {marketplacePackageFormFields}
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <FontAwesomeIcon style={{position: "absolute", bottom: "30px", left: "50px"}} size="xl" icon={faImages} className="icon" onClick={() => onEditMediaAssets()} />
                            <Button style={{ margin: 10, width: 100 }} variant="primary"
                                onClick={event => props.onSave(props.marketplacePackage)}
                                title={validOffer ? "" : "Please correct any errors indicated in red above before submitting."}
                                disabled={!validOffer || !isModified}>{"SAVE"}</Button>
                            <Button style={{ margin: 10, width: 100 }} variant="default"
                                onClick={event => props.onCancel(props.marketplacePackage)}>{"CANCEL"}</Button>
                        </div>
                </main>
            </div>
        </div>
    );
}
