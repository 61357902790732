import React from 'react';
import { MarketplaceItem, OfferField, visibleToOfferAndMetaWorkFlow } from './MarketplaceItem';
import { AUGER_OFFER_TYPES, MAX_UPLOAD_SIZE } from '../../Constants/enums';
import { DropDownFormField, TagFormField, TextFormField } from '../../Components/FormFields/FormField';
import { Col, Container, Row } from 'react-bootstrap';
import { MarketplaceIngestRequest, MarketplacePackageData, MisPackageContentType, MisTag, SubmissionType, MisMediaAsset, MisPackageDetailsResponse, MarketplaceSummary, MarketplaceSummaryData, getMarketplaceSummary } from './MarketplaceDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPaperPlane } from '@fortawesome/free-regular-svg-icons';

const { v4: uuidv4 } = require('uuid');

interface CategorySections {
    [key: string]: string[];
}

export class MarketplacePackage extends MarketplaceItem {
    offerTitle = new OfferField<string | null>(null, "Offer Title", true);
    packageContentType = new OfferField<string | null>(null, "Pack Type", true, visibleToOfferAndMetaWorkFlow);
    packageVersion = new OfferField<string | null>(null, "Package Version", false);
    publisherId = new OfferField<string | null>(null, "Publisher ID", true);
    developerId = new OfferField<string | null>(null, "Developer Id", true);
    priceInUSD = new OfferField<string | null>(null, "Price In USD", true);
    offerType = new OfferField<string | null>(AUGER_OFFER_TYPES.MIS_OFFER, "Offer Type", true);
    packageName = new OfferField<string | null>(null, "Package Name", true);
    submissionType = new OfferField<string | null>(null, "Submission Type", false);
    title = new OfferField<string | null>(null, "Title", true);
    category = new OfferField<string | null>(null, "Category", true);
    section = new OfferField<string | null>(null, "Section", true);
    ingestionStatus = new OfferField<string | null>(null, "Ingestion Status", false);
    mediaAssets = new OfferField<MisMediaAsset[]>([], "Media Assets", false);
    deletedFiles = new OfferField<string[]>([], "Deleted Files", false);
    tags = new OfferField<MisTag[]>([], "Tags", false);

    constructor(data: any) {
        super(data);
        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];

                    if (offerField.convertJson === true) {
                        try {
                            offerField.value = JSON.parse(offerField.value as string)
                        } catch(exception) { }
                    }

                } else {
                    console.log(`Can't find prop "${prop}" in ${this.offerType.value} model!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${this.offerType.value} model!`);
                }
            }
        }

        if (this.priceInUSD.value) {
            let price = parseFloat(this.priceInUSD.value as string);

            this.priceInUSD.value = price.toFixed(2);
        }
    }

    get MarketplaceIngestRequest(): MarketplaceIngestRequest {
        return {
            submissionId: uuidv4(),
            submissionType: SubmissionType.Draft,
            businessInfo: {
                priceInUSD: this.priceInUSD.value as string,
                thirdPartyShortName: this.publisherId.value as string
            },
            packageName: this.packageName.value as string,
            developerId: this.developerId.value as string,
            publisherId: this.publisherId.value as string,
            marketplaceInfo: {
                title: this.title.value as string,
                tags: this.tags.value,
                layout: { mediaAssets: this.mediaAssets.value },
                packageContentType: this.packageContentType.value as MisPackageContentType,
                sections: [{
                    category: (this.category.value as string).toUpperCase(),
                    section: (this.section.value as string).toUpperCase()
                }]
            },
            localizedTexts: {
                "en-US": {
                    "texts": {
                        "Description": "This is a description."
                    }
                }
            },
            deleteExistingFiles: false,
            deletedFilenames: this.deletedFiles.value,
            waitForContentIngestion: true,
            // rentalInfo: {
            //     freeTrial: {
            //         durationInDays: 1,
            //         version: "3"
            //     },
            //     prices: [
            //         {
            //             priceInUSD: "1.99",
            //             durationInDays: 3
            //         }
            //     ]
            // }
        }
    }

    getSubmissionFormFields(formInfoCallback: <V>(offer: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, value?: V) => void, isNew: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {
        const offerTypeOptions = Object.entries(MisPackageContentType).map(([key, value]) => {
            return {
                option: value,
                disabled: false
            };
        });

        const categorySections: CategorySections = {
            "AIRCRAFT": [
                "AIRPLANE",
                "ROTORCRAFT",
                "ULM",
                "GLIDER",
                "BALLOON",
                "AVIONIC",
                "OTHER"
            ],
            "AIRPORTS": [
                "AIRPORT",
                "HELIPORT",
                "GLIDEPORT",
                "BALLOONPORT",
                "SEAPLANE BASE",
                "ULTRALIGHT"
            ],
            "WORLD": [
                "LANDMARK",
                "SCENERY",
                "ENVIRONMENT",
                "WORLD UPDATE",
                "TRAFFIC",
                "WILDLIFE",
                "GROUND SERVICE",
                "LIGHTING"
            ],
            "CUSTOMIZATION": [
                "LIVERY"
            ]
        }

        const categoryOptions = Object.keys(categorySections).map(value => { return { option: value, disabled: false } });
        const sectionOptions = categorySections[(this.category.value as string)?.toUpperCase() ?? categoryOptions[0].option].map(value => { return { option: value, disabled: false } });
        const developerIdOptions = (context?.developerIds as string[])?.map((developerId: string) => { return { option: developerId, disabled: false }});
        const publisherIdOptions = (context?.publisherIds as string[])?.map((publisherId: string) => { return { option: publisherId, disabled: false }});

        const packageNameOptions = (context?.packageNames as string[])?.map((packageName: string) => { return { option: packageName, disabled: false }});

        if (!packageNameOptions.find(o => o.option === this.packageName.value)) {
            packageNameOptions?.push({ option: this.packageName.value as string, disabled: false });
        }

        const tagOptions = Object.keys(MisTag).map(value => { return { key: value, value: value } });
        
        return (
            <Container key={"details"}>
                <Row className="show-grid">
                    <Col md={6}>
                        <TextFormField<MarketplacePackage>
                            key={"offerTitle"}
                            context={this}
                            value={this.title.value ?? ""}
                            title={"Title"}
                            placeholder={"Title"}
                            helpContent={"Extracted from package."}
                            propertyName={"title"}
                            returnCallback={formInfoCallback}
                            isNew={isNew}
                            offerUndo={false}
                            validateCallback={this.offerTitle.validate} />
                    </Col>
                    <Col md={2}>
                        <TextFormField<MarketplacePackage>
                            key={"priceInUSD"}
                            context={this}
                            value={this.priceInUSD.value ?? ""}
                            title={"Price in USD"}
                            placeholder={"9.99"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"priceInUSD"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.priceInUSD.validate} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageName"}
                            context={this}
                            value={this.packageName.value ?? ""}
                            title={"Package Name"}
                            placeholder={"Package Name"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={!isNew}
                            propertyName={"packageName"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageName.validate}
                            optionsList={packageNameOptions ?? []} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"packageContentType"}
                            context={this}
                            value={(this.packageContentType.value === null) ? MisPackageContentType.Unknown : this.packageContentType.value}
                            title={"Content Type"}
                            isNew={isNew}
                            helpContent={"Extracted from package."}
                            propertyName={"packageContentType"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.packageContentType.validate}
                            optionsList={offerTypeOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"category"}
                            context={this}
                            value={(this.category.value as string)?.toUpperCase()}
                            title={"Category"}
                            isNew={isNew}
                            helpContent={"Extracted from package."}
                            propertyName={"category"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.category.validate}
                            optionsList={categoryOptions} />
                    </Col>
                    <Col md={4}>
                        <DropDownFormField<MarketplacePackage>
                            key={"section"}
                            context={this}
                            value={(this.section.value as string)?.toUpperCase()}
                            title={"Section"}
                            isNew={isNew}
                            helpContent={"Extracted from package."}
                            propertyName={"section"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.section.validate}
                            optionsList={sectionOptions} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col md={6}>
                        <DropDownFormField<MarketplacePackage>
                            key={"publisherId"}
                            context={this}
                            value={this.publisherId.value ?? ""}
                            title={"Publisher Id"}
                            placeholder={"Publisher Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={!isNew}
                            propertyName={"publisherId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.publisherId.validate}
                            optionsList={publisherIdOptions ?? []} />
                    </Col>
                    <Col md={6}>
                        <DropDownFormField<MarketplacePackage>
                            key={"developerId"}
                            context={this}
                            value={this.developerId.value ?? ""}
                            title={"Developer Id"}
                            placeholder={"Developer Id"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            disabled={!isNew}
                            propertyName={"developerId"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            validateCallback={this.developerId.validate}
                            optionsList={developerIdOptions ?? []} />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col>
                        <TagFormField<MarketplacePackage>
                            key={"tags"}
                            context={this}
                            value={this.tags.value as string[]}
                            title={"Tags"}
                            placeholder={"TAG"}
                            helpContent={"Extracted from package."}
                            isNew={isNew}
                            propertyName={"tags"}
                            returnCallback={formInfoCallback}
                            offerUndo={false}
                            optionsList={tagOptions} />
                    </Col>
                </Row>
            </Container>);
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    static getColumnHeaders(onEdit: (index: number, submission: MarketplaceSummaryData) => void, onRelease: (index: number, submission: MarketplacePackage) => void, onEditMediaAssets: (submission: MarketplacePackage) => void, isFirstParty = false) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Publisher Id',
                accessor: 'publisherId', // String-based value accessors!
                filterable: true,
                maxWidth: 80,
                style: columnStyle,
                show: isFirstParty
            },
            {
                Header: 'Developer Id',
                accessor: 'developerId', // String-based value accessors!
                filterable: true,
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Title',
                accessor: 'title', // String-based value accessors!
                filterable: true,
                sortable: true,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getMarketplaceSummary(row.original).title
                )
            },
            {
                Header: 'Ingestion Status',
                accessor: 'ingestionStatus', // String-based value accessors!
                filterable: true,
                maxWidth: 65,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getMarketplaceSummary(row.original).ingestionStatus
                )
            },
            {
                Header: 'Release Status',
                accessor: 'releaseStatus', // String-based value accessors!
                filterable: true,
                maxWidth: 65,
                style: columnStyle,
                Cell: ({row}: any) => (
                    getMarketplaceSummary(row.original).releaseStatus
                )
            },
            {
                Header: 'Package',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'packageName', // String-based value accessors!
                        filterable: true,
                        style: columnStyle
                    },
                    {
                        Header: 'Content Type',
                        accessor: 'packageContentType', // String-based value accessors!
                        filterable: true,
                        maxWidth: 65,
                        style: columnStyle,
                        Cell: ({row}: any) => (
                            getMarketplaceSummary(row.original).packageContentType
                        )
                    }
                ]
            },
            {
                accessor: 'release',
                sortable: false,
                filterable: false,
                style: columnStyle,
                Cell: ({row}: any) => (
                    <FontAwesomeIcon icon={faPaperPlane} style={{cursor: "pointer"}} className="icon" onClick={() => onRelease(row.index, row.original)} />
                )
            },
            {
                accessor: 'edit',
                sortable: false,
                filterable: false,
                style: columnStyle,
                Cell: ({row}: any) => (
                    <FontAwesomeIcon icon={faEdit} style={{cursor: "pointer"}} className="icon" onClick={() => onEdit(row.index, row.original)} />
                )
            }
        ]

        return columns;
    }

    static getColumnDetailsHeaders() {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }

        const columns = [
            {
                Header: 'Publisher Id',
                accessor: 'publisherId', // String-based value accessors!
                maxWidth: 80,
                style: columnStyle
            },
            {
                Header: 'Developer Id',
                accessor: 'developerId', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Package Version',
                accessor: 'packageVersion', // String-based value accessors!
                maxWidth: 65,
                style: columnStyle
            }
        ]

        return columns;
    }

    convertToColumnData() {
        var reportEntry = this.export();

        return (
            {
                submission: this,
                packageVersion: reportEntry.packageVersion,
                publisherId: reportEntry.publisherId,
                releaseStatus: reportEntry.releaseStatus,
                developerId: reportEntry.developerId,
                packageName: reportEntry.packageName,
                packageContentType: reportEntry.packageContentType,
                priceInUSD: reportEntry.priceInUSD,
                title: reportEntry.title,
                ingestionStatus: reportEntry.ingestionStatus
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "developerId",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "title",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}