import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { center } from '../Constants/styles'
import { AUGER_OFFER_TYPES } from '../Constants/enums'
import { GetAllSubmissions, GetReleaseOwners } from '../Api/WebApi'
import { DropdownSelect } from './DropdownSelect';
import { OfferList } from './OfferList';
import * as utils from '../Constants/utils';
import { Link, useNavigate } from 'react-router-dom';
import Auth from '../Auth/Auth';

interface Props {
    auth: Auth;
}
  
  function ContentBrowser(props: Props) {
    const [offerType] = useState(AUGER_OFFER_TYPES.DURABLE_OFFER);
    const [submissions, setSubmissions] = useState<any | null>();
    const [releaseOwner, setReleaseOwner] = useState(localStorage.getItem('lastReleaseOwner'));
    const [releaseOwners, setReleaseOwners] = useState<{id: string, name: string, disabled: boolean}[]>();
    const [error, setError] = useState<any>();

    const creatorName = useMemo(() => props.auth.getCreatorName(), [props.auth]);
    const creatorId = useMemo(() => props.auth.getCreatorId(), [props.auth]);

    useEffect(() => {
        document.title = "Content Browser | Marketplace Content Portal";

        const getReleaseOwners = async () => {
            const result: string[] = await GetReleaseOwners(props.auth);

            let releaseOwners: { id: string, name: string, disabled: boolean }[] = result.map((r: string) => {
                return {
                    id: r,
                    name: r,
                    disabled: false
                };
            });

            releaseOwners.push({
                id: "all",
                name: "All",
                disabled: false
            });

            setReleaseOwners(releaseOwners);
        }

        getReleaseOwners();
    }, [props.auth]);

    useEffect(() => {
        GetAllSubmissions(props.auth, offerType, releaseOwner ?? "all").then((result) => {
            if (result.count === 0) {
                console.log("No Offers Found");
                setSubmissions([]);
                setError("No offers found");
            } else {
                setSubmissions(result.value);
            }
        }).catch((error) => {
            console.log("error", error);
            setSubmissions(undefined);
            setError(error);
        });
    }, [props.auth, offerType, releaseOwner]);

    const selectReleaseOwner = (releaseOwner: string) => {
        console.log("Select release owner:" + releaseOwner);

        setSubmissions(null);
        setReleaseOwner(releaseOwner);
        localStorage.setItem('lastReleaseOwner', releaseOwner);
    }

    const navigate = useNavigate();

    useEffect(() => {
        const lastBrowserPathName = "/contentBrowser/" + offerType;
        navigate(lastBrowserPathName);
    }, [offerType, navigate]);
    
    const isFirstParty = utils.isFirstParty(creatorId);
    const createOfferLink = "/createNewOffer/" + AUGER_OFFER_TYPES.DURABLE_OFFER;

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <Card className="text-center">
                        <div style={{margin: "auto", width: "25%"}}>
                            <h1 style={center}>{creatorName} ({creatorId})</h1>
                            {isFirstParty && releaseOwners && <DropdownSelect value={releaseOwner} options={releaseOwners} returnCallback={selectReleaseOwner}/>}
                        </div>
                        < Link to={createOfferLink}>
                            <Button variant="success" style={{margin: "10px"}} size="sm">SUBMIT NEW</Button>
                        </Link>
                        <OfferList type={offerType} submissions={submissions} auth={props.auth} />
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}

export default ContentBrowser;