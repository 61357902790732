import React, { Component } from 'react';

import { Button, FormCheck } from 'react-bootstrap';

class MultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            optionsList: this.props.optionsList,
            
        };

        if(this.props.value){
            this.state.value = this.props.value.split(",");
        }else{
            this.state.value = [];
        }

        this.state.defaultValue = this.state.value;

        this.updateState = this.updateState.bind(this);
        this.toggleSelect = this.toggleSelect.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.value !== this.props.value){
            if(this.props.value){
                this.setState(() => ({ value: this.props.value.split(",") }));
            }else{
                this.setState(() => ({ value: [] }));
            }
        }
    }

    updateState(event, data){

        const {
            value
        } = this.state;

        /*
        console.log("---------------")
        console.log("value before setting", value);
        console.log("data value", data);*/

        if(value.includes(data)){
            value.splice(value.indexOf(data),1);
        }
        else{
            value.push(data);
        }

        /*
        console.log("Value after setting", value);
        console.log("---------------")*/

        this.setState(() => ({ value: value }));
        this.props.onChange(value.sort().join(","));
        //console.log("here", data);
    }

    toggleSelect(){
        const {
            optionsList,
            value
        } = this.state;
        
        var newValue = []
        var optionsLength = Object.keys(optionsList).length;
        if(value.length !== optionsLength){
            newValue = Object.keys(optionsList);
        }

        this.setState(() => ({ value: newValue }));
        this.props.onChange(newValue.sort().join(","));
    }

    render() {

        const {
            optionsList,
            value
        } = this.state;

        //console.log ("render multi value", value);

        var checkBoxes = Object.keys(optionsList).map((key, index) => {
            
            return <FormCheck key={optionsList[key]} checked={value.includes(optionsList[key])} onChange={(e) => this.updateState(key, optionsList[key])}>{optionsList[key]}</FormCheck>
            //return <option value={key} key={index + 1}>{optionsList[key]}</option>
        }
        )

        if (this.props.sort === true) {
            checkBoxes = checkBoxes.sort((function (a, b) {
                return (a.props.children > b.props.children) ? 1 : -1;
            }))
        }

        if(this.props.selectAllOption){
            var buttonText = "Select All"
            var optionsLength = Object.keys(optionsList).length;
            if(value.length === optionsLength){
                buttonText = "Select None";
            }
    
            checkBoxes.unshift(<Button key={"toggle"} onClick={this.toggleSelect} style={{ padding: 0 }} tabIndex={-1} size="sm" variant="link">{buttonText}</Button>);
        }
        
        return (
            <div>
                {checkBoxes}
            </div>
        );
    }
}

export {
    MultiSelect,
};