import React, { Component } from 'react';
import withRouter from '../withRouter';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import { Modal, Card } from 'react-bootstrap';

class Documentation extends Component {
    constructor(props) {
        super(props);
        this.state = { markdown: '' };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.transformLinkUri = this.transformLinkUri.bind(this);
        this.transformImageUrl = this.transformImageUrl.bind(this);
    }

    async componentDidMount() {
        const page = this.props.params.page;
        const file = await import(`./${this.props.prefix}-${page}.md`);
        const response = await fetch(file.default);
        const text = await response.text();

        this.setState({
            image: null,
            markdown: text,
            showModal: false
        })

        document.title = "Documentation | Marketplace Content Portal";
    }

    handleOpen(event) {
        if (event.target.src) {
            this.setState({
                image: event.target.src,
                showModal: true
            })
        }
    }

    handleClose() {
        this.setState({
            showModal: false
        })
    }

    transformLinkUri(input) {
        const url = (input || '').trim();
        const first = url.charAt(0);

        if (first === '/') {
            const result = this.props.path.replace('/' + this.props.params.page, url);

            return result;
        }
        else {
            const result = uriTransformer(input);

            return result;
        }
    }

    transformImageUrl(input) {
        if (input.startsWith("/Documentation/") || input.startsWith("https//")) {
            return uriTransformer(input);
        } else {
            return "/Documentation/" + input;
        }
    }

    render() {
        const {
            image,
            showModal
        } = this.state


        return (
            <div>
                <Card onClick={this.handleOpen}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={this.state.markdown} transformImageUri={this.transformImageUrl} transformLinkUri={this.transformLinkUri} />
                </Card>
                <Modal size="sm" show={showModal} onHide={this.handleClose} >
                    <img alt="" src={image} style={{ transform: "translate(-50%)", left: "50%", position: "absolute"}} width="250%" />
                </Modal>
            </div>
        );
    }
}

export default withRouter(Documentation);