import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { AUGER_OFFER_TYPES } from '../Constants/enums'
import Auth from '../Auth/Auth';
import * as utils from '../Constants/utils';
import { GetContentPackages, GetMarketplacePackageDetails, GetMarketplacePackages, IngestMarketplaceProduct, TransferToPrerelease, UploadMediaAsset } from '../Api/RomaWebApi';
import { MISModalEdit } from './MISModalEdit';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import { MarketplacePackageData, MarketplaceSummaryData, MisMediaAsset, PublishingTarget, getMarketplaceSummary, getPackageDetailsResponse } from '../Models/AugerOffers/MarketplaceDetails';
import { MarketplaceItem } from '../Models/AugerOffers/MarketplaceItem';
import { MISContentBrowserThumbnails } from './MISContentBrowserThumbnails';
import ReactTable from '../ReactTable';
import { MISContentBrowserDetails } from './MISContentBrowserDetails';
import { center, warningText } from '../Constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';

interface Props {
    auth: Auth;
}

export default function MISContentBrowser(props: Props) {
    const [marketplacePackage, setMarketplacePackage] = useState<MarketplacePackage | null>();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [contentPackages, setContentPackages] = useState<any | null>();
    const [marketplacePackages, setMarketplacePackages] = useState<MarketplaceSummaryData[]>([]);
    const [editMarketplacePackage, setEditMarketplacePackage] = useState(false);
    const [releaseMarketplacePackage, setReleaseMarketplacePackage] = useState(false);
    const [editMediaAssets, setEditMediaAssets] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [error, setError] = useState<any>();

    useEffect(() => {
        GetContentPackages(props.auth).then((result: any) => {
            if (result.count === 0) {
                console.log("No Offers Found");
                setContentPackages([]);
                setError("No offers found");
            } else {
                setContentPackages(result.value);
            }
        }).catch((error) => {
            console.log("error", error);
            setContentPackages(undefined);
            setError(error);
        });

        GetMarketplacePackages(props.auth).then((result: MarketplaceSummaryData[]) => {
            if (result.length === 0) {
                console.log("No Offers Found");
                setMarketplacePackages([]);
                setError("No offers found");
            } else {
                setMarketplacePackages(result);
            }
        }).catch((error) => {
            console.log("error", error);
            setMarketplacePackages([]);
            setError(error);
        });
    }, [props.auth]);

    function processFormInfo(submission: MarketplacePackage | undefined, propertyName: keyof MarketplacePackage, propertyValue?: any) { 
        if (propertyValue == null) {
            return;
        }
    
        submission?.setFieldFromName(propertyName as keyof MarketplaceItem, propertyValue);
    }

    function onNew() {
        setMarketplacePackage(AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, { auth: props.auth }) as MarketplacePackage);
        setIsNew(true);
        setEditMarketplacePackage(true)
    }

    function onEdit(index: number, data: MarketplaceSummaryData) {
        let marketplacePackage = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, getMarketplaceSummary(data)) as MarketplacePackage;

        GetMarketplacePackageDetails(props.auth, marketplacePackage.packageName.value as string).then((result: MarketplacePackageData) => {
            const details = getPackageDetailsResponse(result);

            if (details) {
                marketplacePackage.mediaAssets.value = details.marketplaceInfo.layout?.mediaAssets ?? [];
                marketplacePackage.tags.value = details.marketplaceInfo.tags ?? [];

                marketplacePackage.mediaAssets.value.forEach((mediaAsset: MisMediaAsset) => {
                        mediaAsset.url = details?.files?.find(properties => properties.name === mediaAsset.filename)?.url
                });

                setEditMarketplacePackage(true);
                setIsNew(false);
                setCurrentIndex(index);
                setMarketplacePackage(marketplacePackage);
            }
        })
    }

    function onReleaseRequest(index: number, data: any) {
        setMarketplacePackage(AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, data) as MarketplacePackage);
        setReleaseMarketplacePackage(true);
    }

    function onConfirmReleaseRequest(confirm: boolean) {
        if (confirm) {
            TransferToPrerelease(props.auth, {
                packageName: marketplacePackage?.packageName.value as string,
                developerId: marketplacePackage?.developerId.value as string,
                publisherId: marketplacePackage?.publisherId.value as string,
                transferMarketplace: true,
                transferContent: false,
                publishingTarget: PublishingTarget.PCAndXbox
            });

            setMarketplacePackage(undefined);
        }

        setReleaseMarketplacePackage(false);
    }

    function onEditMediaAssets(data:any) {
        setMarketplacePackage(AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, data) as MarketplacePackage);
        setIsNew(false);
        setEditMediaAssets(true);
    }

    async function onSave(submission: MarketplacePackage) {
        setEditMarketplacePackage(false);

        let marketplaceIngestRequest = submission.MarketplaceIngestRequest;

        marketplaceIngestRequest.filenameUploadUrls = {};

        if (submission.mediaAssets.value) {
            await Promise.all(submission.mediaAssets.value.map(async (mediaAsset: MisMediaAsset) => {
                if (mediaAsset.file) {
                    marketplaceIngestRequest.filenameUploadUrls[mediaAsset.filename as keyof object] = await UploadMediaAsset(props.auth, mediaAsset.file, mediaAsset.filename, marketplaceIngestRequest.submissionId, submission.packageName.value as string, marketplaceIngestRequest.developerId, marketplaceIngestRequest.publisherId);
                }
            }));
        }

        const result = await IngestMarketplaceProduct(props.auth, marketplaceIngestRequest);

        if (currentIndex !== -1) {
            marketplacePackages[currentIndex] = result;
            setCurrentIndex(-1);
        }
    }

    function onCancel() {
        setEditMarketplacePackage(false);
        setEditMediaAssets(false);
        setMarketplacePackage(undefined);
        setCurrentIndex(-1);
    }

    function subComponent (row: any) {
        let submission = AugerOfferFactory.build(AUGER_OFFER_TYPES.MIS_OFFER, row.original) as MarketplacePackage;

        return <MISContentBrowserDetails auth={props.auth} isNew={false} marketplacePackage={submission} packageNames={contentPackages?.map((submission:any) => submission.packageName)} />;
    }

    const isFirstParty = utils.isFirstParty(props.auth.getCreatorId());
    const columns = MarketplacePackage.getColumnHeaders(onEdit, onReleaseRequest, onEditMediaAssets, isFirstParty);
    const noDataText = "No offers found!";

        //loading
    const lastPageSize = JSON.parse(localStorage.getItem('lastBrowserPageSize') ?? "10");

    let lastFilter:string | null | undefined = localStorage.getItem('lastBrowserFilter');

    if (lastFilter && lastFilter !== 'undefined') {
        lastFilter = JSON.parse(lastFilter);
    } else {
        lastFilter = undefined;
    }

    let lastSorted:string | null | undefined = localStorage.getItem('lastBrowserSort');

    if (lastSorted && lastSorted !== 'undefined') {
        lastSorted = JSON.parse(lastSorted);

        if (lastSorted?.length === 0) {
            lastSorted = undefined;
        }
    } else {
        lastSorted = undefined;
    }

    const customFilter = (rows: any[], globalFilterValue: string) => {
        const filterValues = globalFilterValue.split(',');
        const result = rows.filter(row => {
            const result = filterValues.find(fv => {
                return row.cells.find((cell: any) => cell.column?.filterable && (cell.value ?? cell.column?.Cell({row:row}))?.toLowerCase().includes(fv.trim().toLowerCase()));
            });

            return result?.trim();
        });
        
        return result;
    }

    return (
        <main className="container-fluid">
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    {marketplacePackage && <Modal show={editMarketplacePackage} dialogClassName="modal-70w" onHide={() => setEditMarketplacePackage(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Body>
                            <MISModalEdit auth={props.auth} isNew={isNew} onSave={onSave} onCancel={onCancel} processFormInfo={processFormInfo} marketplacePackage={marketplacePackage} packageNames={contentPackages?.map((submission:any) => submission.packageName)} />
                        </Modal.Body>
                    </Modal>}
                    {marketplacePackage && <Modal show={releaseMarketplacePackage} onHide={() => setReleaseMarketplacePackage(false)} backdrop={"static"} keyboard={false}>
                        <Modal.Header>
                            <Modal.Title style={center}>Request Release</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                    <div style={center} >
                                        <h3 style={warningText}>This operation cannot be undone.</h3>
                                        <span>
                                        <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />CANCEL</Button>
                                        <Button style={{margin: 10}} onClick={() => onConfirmReleaseRequest(true)}>OK<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                        </span>
                                    </div>
                        </Modal.Body>
                    </Modal>}
                    {marketplacePackage && <MISContentBrowserThumbnails show={editMediaAssets} auth={props.auth} marketplacePackage={marketplacePackage} onSave={() => onSave(marketplacePackage)} onCancel={onCancel} />}
                    <Card className="text-center">
                        <div style={center} >
                            <Button variant="success" style={{display: "display-inline", margin: "10px"}} size="sm" onClick={() => { onNew() }}>CREATE NEW MARKETPLACE PRODUCT</Button>
                            <div>
                                <em>Tip: Hold shift when sorting to multi-sort!</em>
                                <ReactTable
                                    onSortedChange={(newSorted: string) => {
                                        if (newSorted) {
                                            localStorage.setItem('lastBrowserSort', JSON.stringify(newSorted));
                                        }
                                        }}
                                    onFilteredChange={(filtered:boolean) => {
                                        if (filtered) {
                                            localStorage.setItem('lastBrowserFilter', JSON.stringify(filtered));
                                        } else {
                                            localStorage.removeItem('lastBrowserFilter');
                                        }
                                    }}
                                    onPageSizeChange={(pageSize:string) => {
                                        if (pageSize) {
                                            localStorage.setItem('lastBrowserPageSize', pageSize);
                                        }
                                    }}
                                    style={{clear:'right'}}
                                    data={marketplacePackages}
                                    columns={columns}
                                    loading={marketplacePackages.length === 0}
                                    SubComponent={subComponent}
                                    minRows={0}
                                    noDataText = {noDataText}
                                    defaultSorted={lastSorted ?? (isFirstParty ? MarketplacePackage.defaultFirstPartyListSorting : MarketplacePackage.defaultListSorting)}
                                    defaultFiltered={(lastFilter) ? lastFilter : undefined}
                                    defaultPageSize={lastPageSize ?? 10}
                                    className="-striped -highlight"
                                    customFilter={customFilter}
                                />
                            </div>
                        </div>
                        {error && <p>{error.message}</p>}
                    </Card>
                </div>
            </div>
        </main>
    );
}