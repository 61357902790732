import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextFormField } from './FormFields/FormField';
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';

interface Props {
    marketplacePackage: MarketplacePackage;
}

export function MISContentDetails(props: Props) {

    return (
        <Container key={"details"}>
            <Row className="show-grid">
                <Col md={6}>
                    <TextFormField
                        key={"packageVersion"}
                        context={props.marketplacePackage}
                        value={props.marketplacePackage.packageVersion.value ?? ""}
                        title={"Package Version"}
                        helpContent={"Extracted from package."}
                        propertyName={"packageVersion"}
                        offerUndo={false} />
                </Col>
                <Col md={6}>
                    <TextFormField
                        key={"status"}
                        context={props.marketplacePackage}
                        value={props.marketplacePackage.ingestionStatus.value ?? ""}
                        title={"Status"}
                        propertyName={"ingestionStatus"} />
                </Col>
            </Row>
        </Container>
    )
}