import React, { Component } from 'react';
import ReactTable from "../ReactTable";
import AugerOfferFactory from '../Factories/AugerOfferFactory';
import * as utils from '../Constants/utils';

class OfferList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            augerModel: AugerOfferFactory.getModel(this.props.type),
            auth: this.props.auth,
            offers: null,
            organizedOffers: {}
        };
    }
    
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.submissions !== this.props.submissions) {
            if(this.props.submissions === null){
                this.setState({ offers: null});
            }
            else if(this.props.submissions === undefined){
                this.setState({ offers: undefined});
            }
            else{
                this.setState({ offers: this.props.submissions.map(offer => AugerOfferFactory.build(this.props.type, offer)) });
            }
        }
    }

    componentDidMount() {

    }

    render() {
        const {
            offers,
            augerModel
        } = this.state;

        var tableData = []
        var isFirstParty = utils.isFirstParty(this.state.auth.getCreatorId());
        var columns = augerModel.getColumnHeaders(isFirstParty);
        var loading = true;
        var noDataText = "No offers found!";

           //loading
        if(offers === null){
            noDataText = "";
        }  //error
        else if (offers === undefined){
            loading = false;
            noDataText = "Error retrieving submissions! Please contact your partner manager!";
        }  //finished
        else{
            var uniqueOffers = offers.filter(x => x.workItemPlatform.value === 'PC');
            
            uniqueOffers.forEach(x => {
                x.xbox = offers.find(y => y.packageName.value === x.packageName.value && y.workItemPlatform.value === 'Xbox')
            });
        
            loading = false;
            if (uniqueOffers.length !== 0) {
                tableData = uniqueOffers.map(function (offer) {
                    let report = offer.convertToColumnData();
                    report.xbox = offer.xbox ? offer.xbox.convertToColumnData() : undefined;
                    return report;
                });

                if (isFirstParty === false) {
                    let uniqueCreators = tableData.filter((e, i) => tableData.findIndex(a => a["marketplaceCreator"] === e["marketplaceCreator"]))
                    if (uniqueCreators.length === 0 && uniqueOffers[0].marketplaceCreator === uniqueOffers[0].creatorName) {
                        columns.find(e => e.accessor === 'marketplaceCreator').show = false
                    }
                }
            }
        }

        var lastPageSize = JSON.parse(localStorage.getItem('lastBrowserPageSize'));

        var lastFilter = localStorage.getItem('lastBrowserFilter');

        if (lastFilter && lastFilter !== 'undefined') {
            lastFilter = JSON.parse(lastFilter);
        } else {
            lastFilter = undefined;
        }

        var lastSorted = localStorage.getItem('lastBrowserSort');

        if (lastSorted && lastSorted !== 'undefined') {
            lastSorted = JSON.parse(lastSorted);

            if (lastSorted.length === 0) {
                lastSorted = undefined;
            }
        } else {
            lastSorted = undefined;
        }

        return (
            <div>
                <em>Tip: Hold shift when sorting to multi-sort!</em>
                <ReactTable
                    onSortedChange={(newSorted, column, shiftKey) => {
                        if (newSorted) {
                            localStorage.setItem('lastBrowserSort', JSON.stringify(newSorted));
                        }
                      }}
                    onFilteredChange={(filtered, column) => {
                        if (filtered) {
                            localStorage.setItem('lastBrowserFilter', JSON.stringify(filtered));
                        } else {
                            localStorage.removeItem('lastBrowserFilter');
                        }
                    }}
                    onPageSizeChange={(pageSize) => {
                        if (pageSize) {
                            localStorage.setItem('lastBrowserPageSize', pageSize);
                        }
                    }}
                    style={{clear:'right'}}
                    data={tableData}
                    columns={columns}
                    loading={loading}
                    minRows={0}
                    noDataText = {noDataText}
                    defaultSorted={lastSorted ?? (isFirstParty ? augerModel.defaultFirstPartyListSorting : augerModel.defaultListSorting)}
                    defaultFiltered={(lastFilter) ? lastFilter : undefined}
                    defaultPageSize={lastPageSize ?? 10}
                    className="-striped -highlight"
                />
            </div>
        )

    }
}

export {
    OfferList,
}