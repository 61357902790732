export enum MisTag
{
    WORLD_UPDATE = "WORLD_UPDATE",
    DYN_BUNDLE = "DYN_BUNDLE",
    FORCE_PACK = "FORCE_PACK",
    LEGACY = "LEGACY",
    STORE_OFFICIAL = "STORE_OFFICIAL",
    DEPRECATED = "DEPRECATED",
    DISCOUNT_DELUXE_OR_PREMIUM = "DISCOUNT_DELUXE_OR_PREMIUM",
    STORE_INSTALL_PC = "STORE_INSTALL_PC",
}

export enum MisPackageContentType
{
    Unknown = "Unknown",
    Aircraft = "Aircraft",
    Bundle = "Bundle",
    Currency = "Currency",
    CurrencyPack = "CurrencyPack",
    Instruments = "Instruments",
    Livery = "Livery",
    Misc = "Misc",
    Mission = "Mission",
    Pack = "Pack",
    Recipe = "Recipe",
    Scenery = "Scenery",
    Subscription = "Subscription",
    Widget = "Widget"
}

enum MisPublishingPackageType
{
    Unknown = "Unknown",
    Bundle = "Bundle",
    InternalGroup = "InternalGroup",
    Pack = "Pack",
    Package = "Package",
    Subscription = "Subscription",
}

enum MisPublishingItemType
{
    Unknown = "Unknown",
    Package = "Package",
    PublishingGroup = "PublishingGroup",
}

export enum SubmissionType
{
    Draft = "Draft",
    Prerelease = "Prerelease",
    Release = "Release"
}

export enum RepositoryType
{
    Draft = "Draft",
    Live = "Live"
}

export enum MisMediaAssetTag
{
    Background = "Background",
    Carrousel = "Carrousel",
    Thumbnail = "Thumbnail",
    Touchpoint = "Touchpoint",
    Library = "Library",
    Livery = "Livery",
    Scroller = "Scroller"
}

interface MisBusinessInfo
{
    priceInUSD: string,
    catalogPrice?: string,
    thirdPartyShortName: string,
    thirdPartyUaid?: string,
    releaseDate?: string
}

interface MisMarketplaceInfo
{
    sections: MisSection[],
    locations?: MisLocation[],
    tags?: MisTag[],
    keywords?: string[],
    creator?: string,
    supportContact?: string,
    secondSupportContactLabel?: string,
    secondSupportContactLink?: string,
    title: string,
    layout?: MisMarketplaceLayout,
    packageContentType: MisPackageContentType,
    publishingPackageType?: MisPublishingPackageType,
    publishingItemType?: MisPublishingItemType,
    referencedPackages?: string[]
}

interface MisSection
{
    category: string,
    section: string
}

interface MisLocation
{
    type: string,
    airportCode: string,
    latLong: MisLatLong
}

interface MisLatLong
{
    latitude: string,
    longitude: string
}

export interface MisMarketplaceLayout
{
    mediaAssets: MisMediaAsset[],
}

export interface MisMediaAsset
{
    filename: string,
    tags: MisMediaAssetTag[],
    file?: File,
    url?: string
}

export interface MisPackageContentFileProperties
{
    name: string,
    url: string,
    contentHash: string
}

export interface MisPrices
{
    priceInUSD: string,
    durationInDays: number
}

export interface MisFreeTrial
{
    durationInDays: number,
    version: string
}

export interface MisRentalInfo
{
    freeTrial: MisFreeTrial,
    prices: MisPrices[]
}

export enum MisStatus
{
    Unset = "Unset",
    Live = "Live",
    WaitingForFileUpload = "WaitingForFileUpload",
    Committed = "Committed",
    Processing = "Processing",
    Error = "Error"
}

export interface MisPackageDetailsResponse {
    packageName: string,
    playfabTitleId: string,
    repository?: string,
    businessInfo: MisBusinessInfo,
    submissionId?: string,
    publisherId?: string,
    developerId?: string,
    releaseStatus?: SubmissionType,
    ingestionStatus?: MisStatus,
    latestFreeTrialVersion?: number,
    metadata: object,
    marketplaceInfo: MisMarketplaceInfo,
    rentalInfo: MisRentalInfo,
    files: MisPackageContentFileProperties[],
    localizedTexts: object
}

export interface MarketplaceIngestRequest
{
    submissionId: string;
    submissionType: SubmissionType;
    businessInfo: MisBusinessInfo;
    packageName: string,
    developerId: string,
    publisherId: string,
    metadata?: string[],
    marketplaceInfo: MisMarketplaceInfo,
    filenameUploadUrls?: any,
    deletedFilenames?: string[],
    deleteExistingFiles: boolean,
    localizedTexts?: any,
    waitForContentIngestion: boolean,
    rentalInfo?: MisRentalInfo
}

export interface MarketplaceSummary {
    ingestionVersion: string,
    submissionId: string,
    packageName: string,
    publisherId: string,
    developerId: string,
    priceInUSD: string,
    releaseStatus: SubmissionType,
    ingestionStatus: MisStatus,
    title: string,
    packageContentType: MisPackageContentType,
    publishingItemType: MisPublishingItemType,
    category: string,
    section: string
}

export interface MarketplaceSummaryData {
    packageName: string,
    publisherId: string,
    developerId: string,
    lastModified: string,
    draft: MarketplaceSummary,
    prerelease: MarketplaceSummary,
    release: MarketplaceSummary,
}

export interface MarketplacePackageData {
    packageName: string,
    publisherId: string,
    developerId: string,
    lastModified: string,
    draft: MisPackageDetailsResponse,
    prerelease: MisPackageDetailsResponse,
    release: MisPackageDetailsResponse,
}

export enum PublishingTarget
{
    Xbox = "Xbox",
    PC = "PC",
    PCAndXbox = "PCAndXbox"
}

export interface ProductTransferRequest
{
    packageName?: string;
    developerId?: string;
    publisherId?: string;
    transferMarketplace?: boolean;
    transferContent?: boolean;
    publishingTarget?: PublishingTarget;
}

export interface ProductTransferResponse
{
    packageName?: string;
    marketplaceVersionTransferred?: number;
    pcContentVersionTransferred?: string;
    xboxContentVersionTransferred?: string;
    destination?: SubmissionType;
}

export function getPackageDetailsResponse(marketplacePackageData: MarketplacePackageData): MisPackageDetailsResponse {
    let prereleaseOrRelease = marketplacePackageData.prerelease ?? marketplacePackageData.release;

    return prereleaseOrRelease && (!marketplacePackageData.draft || prereleaseOrRelease.submissionId === marketplacePackageData.draft.submissionId) ? prereleaseOrRelease : marketplacePackageData.draft;
}

export function getMarketplaceSummary(marketplacePackageData: MarketplaceSummaryData): MarketplaceSummary {
    let prereleaseOrRelease = marketplacePackageData.prerelease ?? marketplacePackageData.release;

    return prereleaseOrRelease && (!marketplacePackageData.draft || prereleaseOrRelease.submissionId === marketplacePackageData.draft.submissionId) ? prereleaseOrRelease : marketplacePackageData.draft;
}
